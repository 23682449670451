import React from "react";
import MySaleBox from "./MySaleBox";
import SaleDetail from "./SaleDetail";
import PostData from "../../../packs_helper/scripts/PostData";
import Paginate from "../../Common/Paginate";
import Switcher from "../../Common/Switcher";
// import productDetailImage from "../../../assets_v3/images/product-detail.jpg";

const statuses = [
{
	id: "",
	text: "Tümü",
},
{
	id: "1",
	text: "Onaylandı",
},
{
	id: "0",
	text: "Bekliyor",
},
{
	id: "2",
	text: "İptal Edildi",
},
];

class MySales extends React.Component {
	constructor(props) {
		super(props);

		const { active_tab, selling, id, q } = Object.fromEntries(
			new URL(window.location.href).searchParams
		);
		
		this.selling = parseInt(selling);
		this.id = parseInt(id);

		this.state = {
			current_page: 1,
			sale_count: 0,
			sales: [],
			page: 1,
			activeBox: null,
			activeTab: !active_tab ? '' : active_tab,
			q
		};
		this.GetData = this.GetData.bind(this);
		this.GetDetail = this.GetDetail.bind(this);
		this.ChangePage = this.ChangePage.bind(this);
		this.BoxOpen = this.BoxOpen.bind(this);

		this.ItemDelivered = this.ItemDelivered.bind(this);
		this.ItemCancelled = this.ItemCancelled.bind(this);

		this.ActivateTab = this.ActivateTab.bind(this);
	}

	componentDidMount() {
		this.GetData(this.state.current_page);
	}

	BoxOpen(activeBox) {
		this.setState({
			activeBox,
		});
	}

	ChangePage(current_page) {
		this.setState({ current_page });
		this.GetData(current_page);
	}

	GetData(page) {
		PostData("/satislarim", {
			page,
			filter: this.state.activeTab,
			id: this.id,
			q: this.state.q
		})
			.then((response) => {
				if (response.length) {
					const sale_count = response[0].total_count;
					this.setState({
						sale_count,
						sales: response.map((sale) => ({ ...sale, content: {} })),
						activeBox: this.selling || response[0].id
					}, () => {
						this.selling = null;
					});
				}
			});
	}

	GetDetail(sale_id, kind) {
		PostData("/satis-detayi", { order_detail_id: sale_id, kind })
			.then((response) => {
				const sales = this.state.sales;
				const index = sales.findIndex((sale) => sale.id == sale_id);
				sales[index].content = response;

				this.setState({ sales }, () => sales[index].ref.UpdateMaxHeight());
			})
			.catch((err) => {


			});
	}

	ItemDelivered(sale) {
		PostData("/satici-onayi", { order_detail_id: sale.id })
			.then((response) => {
				// this.GetData(this.state.current_page);
				this.props.ChangeRoute('/mesajlarim?active_tab=' + sale.content.user_code)
			})
			.catch((err) => {


			});
	}

	ItemCancelled(order_detail_id) {
		PostData("/satici-iptal", { order_detail_id })
			.then((response) => {
				this.GetData(this.state.current_page);
			})
			.catch((err) => {


			});
	}

	ActivateTab(activeTab) {
		this.props.ChangeRoute(`/satislarim?active_tab=${activeTab}`);
		this.id = 0;
		this.setState({ activeTab, sales: [], sale_count: 0, current_page: 1 }, () => this.GetData(1));
	}

	render() {
		const { sales, sale_count, activeTab } = this.state;
		return (
			<div className="my-sales-container grid-1">
				<Switcher
					buttons={statuses}
					onChange={this.ActivateTab}
					active={activeTab}
				/>

				<form action="" className="section-content filter-top">
					<div className="input-with-icon search-box">
						<button className="icon">
							<svg>
								<use xlinkHref="#gs-icon-layout-search"></use>
							</svg>
						</button>
						<input 
							type="search" 
							placeholder="Sipariş ID veya ilan başlığıyla ara..." 
							name="q" 
							value={this.state.q} 
							onChange={(e) => { 
								this.setState({q: e.target.value}) 
							}} 
						/>
					</div>
				</form>

				{sales.length ? (
					<React.Fragment>
				{sales.map((sale) => (
					<MySaleBox
						id={sale.id}
						name={sale.name}
						kind={sale.kind}
						status={sale.status}
						image={"/assets/product-detail.jpg"}
						GetDetail={this.GetDetail}
						ref={(ref) => (sale.ref = ref)}
						key={sale.id}
						BoxOpen={this.BoxOpen}
						show={this.state.activeBox === sale.id}
					>
						<SaleDetail
							sale={sale}
							user={this.props.user}
							ChangeRoute={this.props.ChangeRoute}
							ItemDelivered={this.ItemDelivered}
							ItemCancelled={this.ItemCancelled}
							mobile={this.props.mobile}
							key={sale.id}
						/>
					</MySaleBox>
				))}
				<Paginate
					onChange={this.ChangePage}
					total_count={sale_count}
					current_page={this.state.current_page}
					per_page={15}
				/>
				</React.Fragment>
				) : (
				<div className="alert">Listelenecek satış işlemi bulunamadı!</div>
			)}
			</div>
		);
	}
}

export default MySales;
