const global_var = {
    AWS_URL: "https://img.gamesatis.com",
    OLD_AWS_URL: "https://images.gamesatis.com",
    BASE_URL: window.location.origin,
    PROFILE_URL: "/profilim",
    PROFILE_STORE_URL: '/magaza-panelim',
    AvatarSetPath: "/assets/avatar-set/avatar-",
    AvatarUploadedPath: "/avatars/",
    AvatarExtension: ".jpg",
    boolean_sub_products: [1133669, 1133670], // Avatar, Banner,
    constant_sub_products: [1201769], // Çoklu İlan Ekleme
    currency_sub_products: [1201811], // İlan Ücreti
    percentage_sub_products: [1201871, 1363780], // Komisyon, Boost İndirim Oranı
    PACKAGE_PRODUCTS: { // Paket içerisindeki tüm product ID ler
        AD_PRODUCT_ID: 361808, // İlan Hakkı
        SMS_PRODUCT_ID: 361807, // SMS Hakkı
        BOOST_1_HOUR_PRODUCT_ID: 200345,
        BOOST_2_HOUR_PRODUCT_ID: 200353,
        BOOST_3_HOUR_PRODUCT_ID: 200354,
        BOOST_12_HOUR_PRODUCT_ID: 514202,
        BOOST_24_HOUR_PRODUCT_ID: 1449544,
        BAZAAR_BOOST_1_HOUR_PRODUCT_ID: 301748,
        MAIN_PAGE_BOOST_3_HOUR_PRODUCT_ID: 1379305,
        MAIN_PAGE_BOOST_24_HOUR_PRODUCT_ID: 2559464,
        AVATAR_PRODUCT_ID: 1133669,
        BANNER_PRODUCT_ID: 1133670,
        MULTI_STOCK_PRODUCT_ID: 1201769, // Çoklu Stok Ekleme
        AD_COST_PRODUCT_ID: 1201811, // İlan Ücreti
        STORE_COMMISSION_PRODUCT_ID: 1201871, // Komisyon
        BOOST_DISCOUNT_RATE_PRODUCT_ID: 1363780, // Boost İndirim Oranı
    },
    GAMESATIS_MESSAGE_USERCODE: "NRWF3PJWOQGH3XB",
    MIN_CASHOUT: 25,
    CASHOUT_COMMISSION: 5,
    MIN_PRICE: {
        ACCOUNT: 25,
        BOOST: 10,
        ITEM: 25
    },
    STORE_LEVELS: {
        "0": {
            icon: "new-store",
            label: "Yeni"
        },
        "1": {
            icon: "bronze",
            label: "Bronz"
        },
        "2": {
            icon: "silver",
            label: 'Silver'
        },
        "3": {
            icon: "gold",
            label: 'Gold'
        },
        "4": {
            icon: "master",
            label: 'Master'
        }
    },
    DIGITAL_CODE_MAX_ROW: 50,
    LIMIT_CHANGE_RATE: 1000,
    RATE_AFTER_LIMIT: 5,
    DELIVERY_TIMES: [1,2,3,6,12]
}
export default global_var;